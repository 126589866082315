import React, { useMemo } from "react";
import { Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { PerformanceCardInterface } from "../../interface/PerformanceCardInterface";
import { useAtom } from "jotai";
import { newVsReturningAtom } from "atoms";
import { calculateRoundFactor } from "@components/ChannelControlMetrics/ConversionValueChart";
import { Tooltip, TooltipContent, TooltipTrigger } from "@ui";
import RateDisplay from "./RateDisplay";

/**
 * Implements the reusable component for card.
 *
 * @param {PerformanceCardInterface} props
 * @return {CustomElement}
 */
export function PerformanceCard(props: PerformanceCardInterface) {
  const {
    title,
    value,
    rate,
    tooltip,
    chartData,
    isIncreasing,
    chartWidth,
    hasSpace = true,
    bgColor,
    isReduced,
    label,
    compared,
    hideRate,
  } = props;

  const [newVsReturning] = useAtom(newVsReturningAtom);
  const maxValue = useMemo(() => {
    let max = 0;
    if (chartData) {
      for (const entry of chartData) {
        if (entry.value > max) {
          max = entry.value;
        }
      }
    }
    return max;
  }, [chartData]);
  const chartRoundFactor = useMemo(
    () => calculateRoundFactor(maxValue),
    [maxValue]
  );

  const transformLabel = (label: string) => {
    const transformConfig: {
      [key: string]: string;
    } = {
      ROAS: "Aquisition ROAS",
    };

    if (newVsReturning === "new" && transformConfig[label]) {
      return transformConfig[label];
    }

    return label;
  };

  const titleWrap = label ? "flex-wrap" : "";
  const content = (
    <div
      className={`flex items-center h-full lg:mb-0 mb-3 ${
        bgColor ? bgColor : "bg-gray-100 dark:bg-gray-800"
      } rounded-lg ${hasSpace ? "p-4" : "px-4 py-3"} justify-between`}
    >
      <div className="flex h-full flex-col items-start">
        <div
          className={`text-base text-foreground font-medium break-words flex items-center ${titleWrap} `}
        >
          <div className="mr-1"> {transformLabel(title)}</div>
          {label && (
            <p className="text-foreground text-sm rounded-md py-1 px-2 bg-gray-100 dark:bg-gray-600 inline">
              {label}
            </p>
          )}
          {tooltip}
        </div>
        <div
          className={` ${
            isReduced ? "text-xl" : "text-2xl"
          } flex-1 text-foreground break-all font-bold mt-2`}
        >
          {value}
        </div>
        {compared && (
          <RateDisplay
            className="mt-2"
            rate={rate}
            isIncreasing={isIncreasing}
            hideRate={hideRate}
          />
        )}
      </div>
      {chartData && (
        <div className="ml-4 bg-gray-50 dark:bg-[#2B2A3E] px-4 py-3 h-28 xl:h-full flex items-center rounded-md box-border">
          <ResponsiveContainer width={chartWidth ?? 80}>
            <LineChart data={chartData} width={100} height={60}>
              <XAxis dataKey="key" hide />
              <YAxis
                hide
                yAxisId="1"
                domain={[
                  0,
                  Math.ceil(maxValue / chartRoundFactor) * chartRoundFactor,
                ]}
              />
              <Line
                type="monotone"
                yAxisId="1"
                dataKey="value"
                strokeWidth={2}
                stroke="#6254FF"
                dot={false}
              />
              <Line
                type="monotone"
                yAxisId="1"
                dataKey="comparedValue"
                strokeWidth={2}
                stroke="#6254FF"
                strokeDasharray="5 5"
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );

  if (
    newVsReturning === "default" &&
    (title === "CAC" || title === "New Customer Rate")
  ) {
    return (
      <Tooltip>
        <TooltipTrigger className="w-full h-full">{content}</TooltipTrigger>
        <TooltipContent>
          <p className="max-w-xs">
            This metric is only available with User Segmentation being active.
            You can do this via the User Segmentation Dropdown on the top
          </p>
        </TooltipContent>
      </Tooltip>
    );
  }

  return content;
}
